export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
};

export const coinArray = {
  97: "BNB",
  84531: "ETH",
  56: "BNB",
};

export const convertDay = (mins) => {
  if (parseInt(mins) < 60) return mins + " mins";
  else if (parseInt(mins) < 1440)
    return Math.floor(mins / 60) + " hrs " + (mins % 60) + " mins";
  else {
    let days = Math.floor(mins / 1440);
    let hours = Math.floor((mins - days * 1440) / 60);
    let minutes = mins % 60;
    return days + " days " + hours + " hours " + minutes + " mins";
  }
};

export const contract = {
  84531: {
    poolfactory: "0x86841b1Edc2e85188463b04294f969B263C41eA2",
    poolmanager: "0x0c70E854F57b38561E72f82e4Fd29eE696441fE9",
    multicallAddress: "0x18540e2a38e3f7eae2a543b572e80304c70965a3",
    lockAddress: "0x1611C1660cC0A59c6a7567Cc50f67A25C0b00bb8",
    feeReceiver: "0xCE035af21b4697101Aaf12C261bc6C79b0e38271",
    dividendTracker: "0xa2532b333215a3330a79609bba0d6a21c9d188f1",
    routers: [
      {
        address: "0x48ec46497ad59acaba41545363cf36fe77786fa7",
        name: "Pancakeswap",
      },
      {
        address: "0xAnotherRouterAddress",
        name: "AnotherRouterName",
      },
    ],
  },
  56: {
    poolfactory: "0x770C22723106C6abD38D1fc05dE6869086F45187",
    poolmanager: "0xfE54D83020c52985Aa8a9c5488b20Ef240Be0dF8",
    multicallAddress: "0xd90548E03194B705AA2f56322fC1D053F6739c42",
    lockAddress: "0xaf640d425198B115A3ce3B2aC4194fcE7927c5dc",
    feeReceiver: "0x09ECc79B9AD7Ef5320d42Fa1Dc77950b0Ed0749E",
    dividendTracker: "0xd8b934580fcE35a11B58C6D73aDeE468a2833fa8",
    routers: [
      {
        address: "0x84dfe8da2c41e8d536748bc0195d7fe6a39d3fae",
        name: "Howswap",
      },
      {
        address: "0xAnotherRouterAddress",
        name: "AnotherRouterName",
      },
    ],
  },
  97: {
    poolfactory: "0x3b025773c5a4FB78B54Bf9AC55638304781503F0",
    poolmanager: "0x43fb080E4cc7f76b1E93168A0aFEc924F7f8Ae07",
    multicallAddress: "0x6e5bb1a5ad6f68a8d7d6a5e47750ec15773d6042",
    lockAddress: "0x51D80DD31446868d6fdCCdc5908E5BB34032d2C6",
    feeReceiver: "0x5b4a58806086fc98885Ee57ADe4db240D4ae4e97",
    dividendTracker: "0x5c7f05a5fb26adeb304b8a8695aabc9b6e7ca037",
    routers: [
      {
        address: "0x84dfe8da2c41e8d536748bc0195d7fe6a39d3fae",
        name: "Howswap",
      },
      {
        address: "0x84dfe8da2c41e8d536748bc0195d7fe6a39d3fae",
        name: "Pancakeswap",
      },
      {
        address: "0x84dfe8da2c41e8d536748bc0195d7fe6a39d3fae",
        name: "Susiswap",
      },
    ],
  },
  default: {
    poolfactory: "0x770C22723106C6abD38D1fc05dE6869086F45187",
    poolmanager: "0xfE54D83020c52985Aa8a9c5488b20Ef240Be0dF8",
    multicallAddress: "0xd90548E03194B705AA2f56322fC1D053F6739c42",
    lockAddress: "0xaf640d425198B115A3ce3B2aC4194fcE7927c5dc",
    feeReceiver: "0x09ECc79B9AD7Ef5320d42Fa1Dc77950b0Ed0749E",
    dividendTracker: "0xd8b934580fcE35a11B58C6D73aDeE468a2833fa8",
    routers: [
      {
        address: "0x84dfe8da2c41e8d536748bc0195d7fe6a39d3fae",
        name: "Howswap",
      },
      {
        address: "0x84dfe8da2c41e8d536748bc0195d7fe6a39d3fae",
        name: "Pancakeswap",
      },
    ],
  },
};

export const getRouterOptions = (chainId) => {
  if (contract[chainId]) {
    return contract[chainId].routers.map((router) => ({
      value: router.address,
      label: router.name,
    }));
  } else {
    return contract.default.routers.map((router) => ({
      value: router.address,
      label: router.name,
    }));
  }
};

